<template>
  <section>
    <ButtonTableAction
      :title="$tc('buttons.delete_button')"
      icon="delete"
      @click.stop="showDeleteModal"
    />
    <Modal
      v-if="deleteModal"
      modal-size="modal-md"
      :title="$tc('modal.delete_modal_header')"
      @close="closeDeleteModal"
    >
      <template slot="body">
        {{ $t('modal.delete_modal_text') }}
      </template>

      <template slot="footer">
        <button
          type="button"
          class="btn btn-inverse router-link-active"
          @click="closeDeleteModal"
        >
          {{ $t('modal.delete_modal_cancel_btn') }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="deleteRecord"
        >
          {{ $t('modal.delete_modal_confirm_btn') }}
        </button>
      </template>
    </Modal>
  </section>
</template>

<script>
import Modal from '@/components/shared/Modal'
import NotifyService from '@/services/NotifyService'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'

export default {
  name: 'ButtonDelete',
  props: {
    storeName: {
      type: String,
      required: true
    },
    storeActionReloadName: {
      type: String,
      default: 'fetch'
    },
    recordId: {
      type: [Number, String],
      required: true
    },
    actionIdParam: {
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      deleteModal: false
    }
  },
  components: {
    ButtonTableAction,
    Modal
  },
  methods: {
    showDeleteModal () {
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    deleteRecord () {
      this.$store.dispatch(`${this.storeName}/deleteRecord`, { [this.actionIdParam]: this.recordId })
        .then(() => {
          const error = this.$store.getters[`${this.storeName}/error`]
          if (error === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$store.dispatch(`${this.storeName}/${this.storeActionReloadName}`)
            this.closeDeleteModal()
          } else {
            NotifyService.setErrorMessage(error)
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>
