<template>
  <div :class="`abstract-data-table ${tableCssClass}`"
       :style="styleWithVariables"
  >
    <div :class="`abstract-data-table__thead ${tableCssClass}__thead`">
      <div
        v-for="(header, headerIndex) in headers"
        :key="`header-${headerIndex}`"
        class="abstract-data-table__th"
        :style="header.style ? header.style : ''"
      >
        {{ header.name }}
        <Tooltip
          v-if="header.tooltip"
          :title="header.tooltip"
          class="abstract-data-table__th__tooltip"
        />
        <span
          v-if="header.subHeader"
          class="abstract-data-table__th-sub"
        >
          {{ header.subHeader }}
        </span>
      </div>
    </div>
    <div class="abstract-data-table__tbody">
      <div
        :class="`abstract-data-table__tr ${tableCssClass}__tr`"
        v-for="(item, index) in items"
        :key="`item-${index}`"
        :title="rowTooltipProp ? item[rowTooltipProp] : ''"
        @click="$emit('row-clicked', item)"
      >

        <slot name="tableBody" :item="item" :index="index" />

        <div
          v-if="showActions"
          class="abstract-data-table__td abstract-data-table__td--btns"
          :style="actionsStyle"
        >
          <span class="abstract-data-table__td-desc">{{ $t("actions") }}</span>
          <slot name="actions" :item="item">
            <ButtonTableAction v-if="showDetailButton(item)" icon="info" @click.stop="infoButtonClick(item)" />
            <ButtonTableAction v-if="showEditButton(item)" icon="edit" @click.stop="editButtonClick(item)" />
            <ButtonTableAction v-if="showDuplicateButton(item)" icon="duplicate" @click.stop="duplicateButtonClick(item)" />
            <ButtonDelete
              v-if="showDeleteButton(item)"
              :store-name="storeName"
              :record-id="item[actionIdParam]"
              :action-id-param="actionIdParam"
              :store-action-reload-name="storeActionReloadName"
            />
          </slot>
        </div>

        <slot name="afterActions" :item="item" :index="index" />
      </div>
    </div>
    <div
      v-if="showPagination"
      class="abstract-data-table__bottom-bar"
    >
      <ArticleTotalCount :total-count="totalCount" />
      <Pagination
        v-if="items.length"
        :value="currentPage"
        @input="$emit('current-page-changed', $event)"
        :page-count="pageCount"
        :click-handler="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Pagination from '@/components/Pagination'
import ArticleTotalCount from '@/components/article/ArticleTotalCount'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import Tooltip from '@/components/tooltip/Tooltip'

export default {
  name: 'AbstractDataTable',
  props: {
    storeName: {
      type: String,
      default: ''
    },
    storeActionReloadName: {
      type: String,
      default: 'fetch'
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    onPageChange: {
      type: Function,
      default: () => {}
    },
    tableCssClass: {
      type: String,
      default: ''
    },
    actionIdParam: {
      type: String,
      default: 'id'
    },
    showActions: {
      type: Boolean,
      default: true
    },
    actionsStyle: {
      type: String,
      default: ''
    },
    rowTooltipProp: {
      type: String,
      default: ''
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    showDetailButton: {
      type: Function,
      default: item => true
    },
    showEditButton: {
      type: Function,
      default: item => true
    },
    showDeleteButton: {
      type: Function,
      default: item => true
    },
    showDuplicateButton: {
      type: Function,
      default: item => false
    },
    /**
     * You can add second value for mobile-view.
     */
    rowHoverMaxHeights: {
      type: Array,
      default: () => ['calc(100% + 2px)']
    },
    columnsGap: {
      type: String,
      default: '20px'
    }
  },
  components: {
    Tooltip,
    ButtonDelete,
    ButtonTableAction,
    Pagination,
    ArticleTotalCount
  },
  computed: {
    pageCount () {
      return Math.ceil(this.totalCount / config.defaults.list.limit)
    },
    styleWithVariables () {
      return {
        '--row-hover-max-height': this.rowHoverMaxHeights[0],
        '--row-hover-max-height-mobile': this.rowHoverMaxHeights[1] ?? this.rowHoverMaxHeights[0],
        '--columns-gap': this.columnsGap
      }
    }
  },
  methods: {
    onButtonClick (item, additionalPath) {
      this.$router.push(`${this.$router.currentRoute.path}/${item[this.actionIdParam]}${additionalPath}`)
    },
    infoButtonClick (item) {
      this.onButtonClick(item, '')
    },
    editButtonClick (item) {
      this.onButtonClick(item, '/edit')
    },
    duplicateButtonClick (item) {
      this.onButtonClick(item, '/duplicate')
    }
  }
}
</script>

<style lang="scss">
  .abstract-data-table {
    border: none;
    display: block;
    @include bp(0 12) {
      margin-top: rem(20px);
    }
    @include bp(12) {
      background: #fff;
      border-bottom-left-radius: rem(6px);
      border-bottom-right-radius: rem(6px);
      padding: rem(30px) rem(30px) 0 rem(30px);
    }
    &__thead {
      background: #F5F5F9;
      border-radius: rem(6px);
      padding: rem(16px) 0 rem(16px) rem(16px);
      @include bp(0 12) {
        display: none;
      }
    }
    &__thead,
    &__tr {
      @include bp(12) {
        display: grid;
        grid-gap: var(--columns-gap);
        align-items: center;
      }
    }
    &__th {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 600;
      color: #8A96AC;
      &:last-child {
        padding-right: rem(20px);
      }
      &__tooltip {
        color: #6599FE;
        cursor: pointer;
      }
    }
    &__tbody {
      @include bp(0 8) {
        display: grid;
        grid-gap: rem(20px);
      }
      @include bp(8 12) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem(20px);
      }
    }
    &__td {
      @include bp(0 12) {
        &:not(:last-child) {
          margin-bottom: rem(15px);
        }
      }
      &--btns {
        @include bp(0 12) {
          display: flex;
          gap: rem(6px);
          flex-wrap: wrap;
        }
        @include bp(12 14) {
          display: grid;
          grid-gap: 3px;
        }
        @include bp(14) {
          display: flex;
          gap: rem(6px);
        }
      }
    }
    &__td-desc {
      @include bp(0 12) {
        background: rgba(101, 153, 254, .2);
        color: #568df7;
        font-weight: 500;
        display: block;
        padding: rem(3px) rem(8px);
        border-radius: rem(3px);
        width: 100%;
        margin-bottom: rem(5px);
      }
      @include bp(12) {
        display: none;
      }
    }
    &__th-sub,
    &__td-sub {
      font-size: rem(13px);
      font-weight: 400;
      opacity: .9;
      display: block;
    }
    &__tr {
      position: relative;
      @include bp(0 12) {
        background: #fff;
        border-radius: rem(6px);
        padding: rem(15px);
        box-shadow: 0 0 12px 2px #dadada;
      }
      @include bp(12) {
        padding: rem(14px) 0 rem(14px) rem(16px);
        align-items: center;
      }
      &:not(:last-child) {
        @include bp(12) {
          border-bottom: 1px solid #D1DBE4;
        }
      }
      &:last-child {
        @include bp(12) {
          border-bottom: 1px solid transparent;
        }
      }
      &::before {
        position: absolute;
        left: rem(-30px);
        top: -1px;
        width: calc(100% + 60px);
        height: calc(100% + 2px);
        content: '';
        z-index: 1;
        background: rgba(101, 153, 254, .1);
        pointer-events: none;
        opacity: 0;
        max-height: var(--row-hover-max-height);
        @include bp(0 12) {
          max-height: var(--row-hover-max-height-mobile);
        }
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
    &__td-title {
      font-family: "Roboto", sans-serif;
      font-size: rem(15px);
      font-weight: 500;
      color: #465674;
      word-break: break-word;
    }
    &__td-title--subtitle {
      display: block;
      font-weight: 400;
      color: #8490A7;
    }
    &__td-title--bold {
      font-weight: 600;
    }
    &__bottom-bar {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      justify-content: space-between;
      margin-top: rem(30px);
      padding-bottom: rem(30px);
    }
  }
</style>
